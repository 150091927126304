@font-face {
  font-family: "GoboldLowplus";
  src: url("public/webfonts/GoboldLowplus.otf") format("truetype");
}
@font-face {
  font-family: "VanCondensed";
  src: url("public/webfonts/VanCondensed.otf") format("truetype");
}
@font-face {
  font-family: "VanCondensed-Light";
  src: url("public/webfonts/VanCondensed-Light.otf") format("truetype");
}
@font-face {
  font-family: "VanCondensed-Bold";
  src: url("public/webfonts/VanCondensed-Bold.otf") format("truetype");
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  background: #0c1114;
  overflow-x: hidden;
}
a {
  color: #333;
  text-decoration: none;
}
p {
  font-family: VanCondensed, sans-serif;
  color: #000000;
  font-size: 18px;
  position: relative;
}
form {
  width: 80%;
  margin: 30px auto 0 auto;
}
input,
textarea {
  font-family: VanCondensed, sans-serif;
  color: #000000;
  font-size: 20px;
  border: none;
  border-bottom: 2px solid black;
  background-color: transparent;
  transform: translate3d(0px, 0px, 0px);
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}
button {
  background-color: transparent;
  border: none;
}
button:focus {
  outline: none;
}
input:focus {
  outline: none;
}
textarea {
  height: 30px;
  resize: none;
}
textarea:focus {
  outline: none;
  resize: none;
}
::placeholder {
  color: #fec908;
}
:-ms-input-placeholder {
  color: #fec908;
}
::-ms-input-placeholder {
  color: #fec908;
}
.row {
  width: 100%;
}
.col-2 {
  display: inline-block;
  width: 16.66%;
}
.no_margin {
  margin: 0;
}
.no_padding {
  padding: 0;
}
/*** button effects ***/
.btn {
  padding: 10px 15px 10px 15px;
  position: relative;
  font-size: 18px;
  border: none;
  text-transform: uppercase;
  transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  user-select: none;
  display: inline-block;
}
.btn p {
  font-family: GoboldLowplus, sans-serif;
  z-index: 1;
  text-align: center;
  letter-spacing: 5px;
  text-indent: 5px;
  padding: 0;
  margin: 0 auto;
}
.btn:before,
.btn:after {
  content: "";
  position: absolute;
  transition: inherit;
  z-index: 0;
}
.btn:hover p {
  transition-delay: 0.6s;
}
.btn:hover:before {
  transition-delay: 0s;
}
.btn:hover:after {
  transition-delay: 0.4s;
}
/* From Top */
.from-top:before,
.from-top:after {
  left: 0;
  height: 0;
  width: 100%;
}
.from-top:before {
  bottom: 0;
  left: 0px;
}
.from-top:after {
  top: 0;
  height: 0;
}
.from-top:hover:before,
.from-top:hover:after {
  height: 100%;
}
/* From Bottom */
.from-bottom:before,
.from-bottom:after {
  left: 0;
  height: 0;
  width: 100%;
}
.from-bottom:before {
  top: 0;
  left: 0px;
}
.from-bottom:after {
  bottom: 0;
  height: 0;
}
.from-bottom:hover:before,
.from-bottom:hover:after {
  height: 100%;
}
/*** General Animations ***/
.transitionY_animation {
  -moz-transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
}
.opacity_animation {
  -moz-transition: opacity 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-transition: opacity 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: opacity 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: opacity;
}
.background_opacity_animation {
  -moz-transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: background-color;
}
/*** FORM RESULTS ***/
form .txt-error,
form .txt-invalid {
  display: none;
  position: relative;
  text-align: center;
  width: 100%;
  margin-top: 10px;
}
form .txt-error,
form .txt-invalid {
  font-family: VanCondensed, sans-serif;
  font-weight: normal;
  color: #c64a4e;
}
form .form-result {
  position: relative;
  float: left;
  width: 100%;
  margin: 5px 0 0;
}
form.form-success .container-success,
form.form-error .txt-error,
form.form-invalid .txt-invalid {
  display: block;
}
input.error,
textarea.error {
  border-bottom: 2px solid #c64a4e !important;
}
form.submit button[type="submit"] {
  color: transparent;
}

form .container-success {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  display: none;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.8);
}
form .container-success div:not(.div-bt-close) {
  font-family: VanCondensed, sans-serif;
  font-size: 20px;
  line-height: 1;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 215px;
  margin: 31px 0 0 0px;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: #3b3b3b;
}
form .container-success h2 {
  position: relative;
  float: left;
  width: 100%;
}
form .container-success .bt-close {
  position: absolute;
  bottom: -68px;
  left: 0;
  width: 100%;
  height: 62px;
  padding: 0;
  transition: all 150ms ease-in-out;
  border: 0;
  background: none;
  background: #3b3b3b;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
form .container-success .bt-close div.div-bt-close {
  position: relative;
  width: 100%;
  height: 100%;
}
form .container-success .bt-close p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;
  color: #ffffff;
  font-size: 30px;
}
.form-success .bt-close:hover,
.form-success .bt-close:active,
.form-success .bt-close:focus {
  background: #000;
}
.form-success .bt-close:hover p,
.form-success .bt-close:active p,
.form-success .bt-close:focus p {
  color: #fec908 !important;
}
/*** General ***/
.section {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}
.column {
  vertical-align: top;
  position: relative;
  display: inline-block;
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.backgroundImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.content {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  padding-top: 7%;
  text-align: center;
}
.pawn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title {
  display: block;
  margin: 0 auto;
}
/*** Section1 ***/
#section1:not(.mobile) {
  margin-top: 79px;
}
#section1 .column.left {
  background-color: rgba(0, 0, 0, 0);
}
#section1 .column.right {
  background-color: rgba(255, 255, 255, 0);
}
/*** Section2 ***/
#section2 .column.right p {
  color: #ffffff;
  margin-top: 15px;
}
#section2 .column.left p {
  margin-top: 40px;
}
/*** Section3 ***/
#section3 .column.left p {
  color: #fff;
  margin-top: 40px;
}
/*** Section4 ***/
#section4 .column.left .content > p {
  margin-top: 50px;
  height: 200px;
}
#section4 .column.left .btn {
  color: #000;
}
#section4 .column.left .btn:hover p {
  color: #fec908;
}
#section4 .column.left .btn:hover:after {
  background: #000;
}
#section4 .column.left .from-top:before,
#section4 .column.left .from-bottom:before {
  border: 2px solid #000;
  border-top: 0;
  border-bottom: 0;
}
#section4 .column.right .text_content > p {
  margin-top: 60px;
  height: 200px;
  color: #ffffff;
}
#section4 .column.right .btn p {
  color: #ffffff;
}
#section4 .column.right .btn:hover p {
  color: #000000;
}
#section4 .column.right .btn:hover:after {
  background: #ffffff;
}
#section4 .column.right .from-top:before,
#section4 .column.right .from-bottom:before {
  border: 2px solid #ffffff;
  border-top: 0;
  border-bottom: 0;
}
/*** section 5 ***/
#section5 .content {
  padding-top: 2%;
}
#section5 .schedule {
  margin-top: 10px;
}
#section5 .schedule-title p {
  margin-bottom: 5px;
}
#section5 .col-2 {
  vertical-align: top;
  padding: 2px;
}
#section5 .box {
  position: relative;
  padding: 4px;
  text-align: center;
  height: 42px;
  vertical-align: top;
}
#section5 .text-content-box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
#section5 .box p,
#section5 .box .box-title {
  white-space: nowrap;
  margin: 0;
}
#section5 .box .box-title {
  font-size: 15px;
}
#section5 .box p {
  font-size: 10px;
}
#section5 .box .box-title {
  font-size: 15px;
  white-space: nowrap;
}
#section5 .box-none {
  opacity: 0;
  height: 25px;
}
#section5 .box-jjuv {
  background-color: #dadada;
}
#section5 .box-ini {
  background-color: #fff;
  border: 1px solid #000;
}
#section5 .box-jj {
  background-color: #fec908;
}
#section5 .box-jj p {
  color: #000000;
}
#section5 .box-kids {
  background-color: #fde89b;
}
#section5 .box-kids p {
  color: #000000;
}
#section5 .box-med {
  background-color: #8a8a8a;
}
#section5 .box-med p {
  color: #ffffff;
}
#section5 .box-yoga {
  background-color: #d6d6d6;
}
#section5 .box-yoga p {
  color: #000000;
}
#section5 .box-boxe {
  background-color: #000000;
}
#section5 .box-boxe p {
  color: #ffffff;
}
#section5 .schedule-gap {
  height: 30px;
}
/*** section6 ***/
#section6 .column.left .text_content {
  margin-top: 40px;
}
#section6 .social_container {
  background-color: #000000;
  margin: 25px 10px 0 10px;
  position: relative;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s;
}
#section6 .social_container:hover {
  background-color: #ffffff;
}
#section6 .social_container:hover .hover {
  display: block;
}
#section6 .social_container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#section6 .social_container .hover {
  display: none;
}
/*** section7 ***/
#section7 ::placeholder {
  color: #000000;
}
#section7 :-ms-input-placeholder {
  color: #000000;
}
#section7 ::-ms-input-placeholder {
  color: #000000;
}
#section7 .column.left .text_content p {
  color: #ffffff;
}
#section7 .text_content {
  margin-top: 40px;
}
#section7 .contacts_containers {
  margin: 10px 0 30px 0;
}
#section7 .contacts_containers img,
#section7 .contacts_containers p {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 5px;
}
#section7 .social_containers,
#section6 .social_containers {
  text-align: center;
}
#section7 .social_container {
  margin: 25px 10px 0 10px;
  position: relative;
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s;
}
#section7 .social_container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#section7 .phone_content,
#section7 .email_content {
  margin-top: 25px;
}
#contacts_containers * {
  display: inline-block;
}
#section7 .social_container:hover {
  background-color: #fec908;
}
#section7 .column.right .btn {
  color: #000;
}
#section7 .column.right .btn:hover p {
  color: #fec908;
}
#section7 .column.right .btn:hover:after {
  background: #000;
}
#section7 .column.right .from-top:before,
#section7 .column.right .from-bottom:before {
  border: 2px solid #fec908;
  border-top: 0;
  border-bottom: 0;
}
/*** ACADEMY PAGE ***/
/*** slide ***/
.slide,
.carousel-inner {
  height: 100%;
}
.slide_container {
  height: 100%;
  -moz-transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
  will-change: opacity;
}
.slide_hover {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  top: 0;
  left: 0;
}
.slide_container:hover .slide_hover {
  opacity: 1;
}
/*** section1 ***/
.big_container_academy .section {
  overflow: initial;
}
.big_container_academy .content {
  padding-top: 5%;
}
.content.slide {
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;
  background-repeat: no-repeat;
  z-index: 1;
  background-position: center center;
  background-size: cover;
}
#section1Academy {
  position: relative;
}
#section1Academy .title {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
#section1Academy .column.left {
  background-color: rgba(0, 0, 0, 1);
}
#section1Academy .column.right {
  background-color: rgba(255, 255, 255, 1);
}
#section1Academy .column.left .content {
  height: auto;
  padding: 0;
  width: 90%;
  position: absolute;
  top: 70%;
  right: 0;
  transform: translateY(-50%);
}
#section1Academy .column.left .content img {
  float: left;
  margin-left: 10px;
}
/*** section 2 ***/
#Section2Academy .column.left {
  background-color: rgba(255, 255, 255, 1);
}
#Section2Academy .column.left p {
  margin-top: 30px;
}
/*** section 3 ***/
#Section3Academy .column.right {
  background-color: #fec908;
}
#Section3Academy .column.right p {
  margin-top: 30px;
}
/*** section 4 ***/
#Section4Academy .column.left p {
  color: #ffffff;
  margin-top: 30px;
}
/*** section 5 ***/
#Section5Academy .column.right {
  background-color: rgba(255, 255, 255, 1);
}
#Section5Academy .column.right p {
  margin-top: 30px;
}
/*** section 6 ***/
#Section6Academy .column.left {
  background-color: rgba(0, 0, 0, 1);
}
#Section6Academy .column.left p {
  margin-top: 30px;
  color: #ffffff;
}
/*** section 7 ***/
#Section7Academy .column.right {
  background-color: #fec908;
}
#Section7Academy .column.right p {
  margin-top: 30px;
}
/*** section 8 ***/
#Section8Academy .column.left {
  background-color: rgba(255, 255, 255, 1);
}
#Section8Academy .column.left p {
  margin-top: 30px;
}
/*** section 9 ***/
#Section9Academy .column.left {
  background-color: rgba(0, 0, 0, 1);
}
#Section9Academy .column.left .content {
  padding-top: 7%;
}
#Section9Academy .text_content {
  margin-top: 40px;
}
#Section9Academy .column.left .btn {
  background-color: #fff;
}
#Section9Academy .column.left p {
  color: #ffffff;
}
#Section9Academy .column.left .btn p {
  color: #fec908;
  margin-top: 3px;
}
#Section9Academy .column.left .btn:hover p {
  color: #000000;
}
#Section9Academy .column.left .btn:hover:after {
  background: #fec908;
}
#Section9Academy .column.left .from-top:before,
#Section9Academy .column.left .from-bottom:before {
  border: 2px solid #fec908;
  border-top: 0;
  border-bottom: 0;
}
#Section9Academy .column.left input,
#Section9Academy .column.left textarea {
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
}
#Section9Academy .column.right {
  background-color: rgba(255, 255, 255, 1);
}
#Section9Academy .contacts_containers {
  margin: 10px 0 10px 0;
}
#Section9Academy .contacts_containers img,
#Section9Academy .contacts_containers p {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 5px;
}
#Section9Academy .social_containers {
  text-align: center;
}
#Section9Academy .social_container {
  margin: 25px 10px 0 10px;
  position: relative;
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s;
}
#Section9Academy .social_container {
  background-color: #000000;
}
#Section9Academy .social_container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#Section9Academy .phone_content,
#Section9Academy .email_content {
  margin: 30px 5px 0 5px;
  width: 45%;
  white-space: nowrap;
  display: inline-block;
}
#Section9Academy .phone_content {
  text-align: right;
}
#Section9Academy .email_content {
  text-align: left;
}
#Section9Academy .social_container:hover {
  background-color: #fec908;
}
/*** carrousel and modal video ***/
.carousel-item {
  height: 100%;
}
.carousel-item .image_container img,
.carousel-item .video_container img {
  width: 100%;
}
.carousel-indicators {
  bottom: 20px;
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #ffffff;
  border-radius: 50%;
}
.play_video {
  position: absolute;
  z-index: 1;
  opacity: 0.5;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}
.play_video:hover {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .carousel-control-next {
    right: 15px;
  }
  .section {
    background-size: auto 50%;
    background-position: top center;
  }
  .column {
    height: 50%;
    width: 100%;
    overflow: hidden;
  }
  .big_container .content {
    padding-top: 15%;
  }
  /*** section2 ***/
  #section2 .column.left p {
    color: #ffffff;
  }
  #section2 .column.right p {
    color: #000000;
  }
  /*** section3 ***/
  #section3 .column.right .btn {
    color: #000;
  }
  #section3 .column.right .btn:hover p {
    color: #fec908;
  }
  #section3 .column.right .btn:hover:after {
    background: #000;
  }
  #section3 .column.right .from-top:before,
  #section3 .column.right .from-bottom:before {
    border: 2px solid #000;
    border-top: 0;
    border-bottom: 0;
  }
  /*** academy page mobile ***/
  .big_container_academy .content {
    padding: 30px 0 30px 0;
  }
  .big_container_academy #section1Academy .column {
    width: 50%;
    height: 100%;
  }
  .big_container_academy #section1Academy .title {
    top: 65%;
    width: 50%;
  }
  #Section3Academy .column.left,
  #Section5Academy .column.left,
  #Section7Academy .column.left {
    background-color: #ffffff;
  }
  #Section3Academy .column.left p,
  #Section5Academy .column.left p,
  #Section7Academy .column.left p,
  #Section9Academy p {
    margin-top: 30px;
  }
  #Section3Academy .column.right,
  #Section5Academy .column.right,
  #Section7Academy .column.right {
    background-color: #000000;
  }
  .slide_hover {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  /*** Home ***/
  /*** section 3 ***/
  .section_dif_mobile {
    height: auto !important;
  }
  #section3 .column.right {
    padding-bottom: 50px;
  }
  /* #section3 .column.right .title {
    width: auto;
    transform: scale(0.8);
  } */
  #section3 .column.right p {
    margin-bottom: 30px;
  }
  #section4 .column.right .text_content > p {
    height: auto;
  }
  /*** section 5 ***/
  #section5 .box {
    height: 70px;
  }
  #section5 .box-none {
    height: 20px;
  }
  #section5 .box p,
  #section5 .box .box-title {
    white-space: normal;
    font-size: 9px;
  }
  /*** Academy page ***/
  .big_container .content {
    padding-top: 30%;
  }
  .big_container_academy #section1Academy .title {
    width: 60%;
  }
  #Section9Academy .phone_content,
  #Section9Academy .email_content {
    width: 100%;
    text-align: center;
  }
  #Section9Academy .email_content {
    margin-top: 10px;
  }
  .carousel-item .image_container img,
  .carousel-item .video_container img {
    width: auto;
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  /* .title,
  .subtitle {
    width: 90%;
  }
  #Section2Academy .title,
  #Section3Academy .title,
  #Section4Academy .title,
  #Section6Academy .title,
  #Section7Academy .title,
  #Section8Academy .title {
    width: 90%;
  }
  #Section5Academy .column.left .title,
  #Section9Academy .column.right .title {
    width: auto;
    transform: scale(0.9);
  } */
}
@media only screen and (max-width: 480px) {
  /* .big_container_academy #section1Academy .title {
    width: 80%;
  }
  #Section5Academy .column.left .title,
  #Section9Academy .column.right .title {
    width: 80%;
    transform: scale(1);
  } */
}
@media only screen and (max-width: 1280px) and (min-width: 993px) {
  .title:not(.title_sm) {
    width: 100%;
  }
}
@media only screen and (max-width: 630px) {
  .title_lg {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .title:not(.title_sm) {
    width: 100%;
  }
  .subtitle {
    width: 100%;
  }
}
