.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: 28px;
  background-color: #ffd96a;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
}
.marker:hover {
  z-index: 1;
}
