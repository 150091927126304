.video_modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
}
.video_modal.show {
  display: block;
}
.video_modal .frame {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  top: 50%;
  height: 0;
  padding-bottom: 56.25%;
}
.video_modal .closemodal {
  border-radius: 60px;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
  padding: 0;
  display: block;
  overflow: hidden;
  width: 60px;
  height: 60px;
  white-space: nowrap;
  text-indent: -9999em;
  border: none;
  color: transparent;
  background: #fec908
    url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOSIgaGVpZ2h0PSIxOSI+CiAgPHBhdGggZmlsbD0iI0ZGRiIgZD0iTTE5IDJsLTItMi03LjUgNy41TDIgMCAwIDJsNy41IDcuNUwwIDE3bDIgMiA3LjUtNy41TDE3IDE5bDItMi03LjUtNy41Ii8+Cjwvc3ZnPgo=)
    no-repeat 50%;
}
.video_modal iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 4em;
  vertical-align: middle;
}
@media only screen and (max-width: 992px) {
  .video_modal,
  .video_modal iframe {
    padding: 2em;
  }
}
