.invisible {
  opacity: 0;
}
.header nav li a:hover,
.header nav li a:active,
.header nav li a:focus {
  color: #000000;
}
.header nav .logo img {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 0px;
  z-index: 5;
  transform: translateX(-50%);
}
#bt-menu {
  background-color: transparent;
  border: none;
}
@media only screen and (min-width: 993px) {
  .header {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 5;
    border-width: 0 0 2px;
    background: #fec908;
    border-bottom: solid 5px #000000;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -ms-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
  }
  .header .container {
    position: relative;
    text-align: center;
  }
  .header nav {
    position: relative;
    width: 100%;
  }
  .header nav ul {
    position: relative;
    list-style-type: none;
    margin: 0;
    -webkit-transition: padding 100ms ease-in-out;
    -moz-transition: padding 100ms ease-in-out;
    -ms-transition: padding 100ms ease-in-out;
    -o-transition: padding 100ms ease-in-out;
    transition: padding 100ms ease-in-out;
  }
  .header nav li {
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }
  .header nav .logo {
    cursor: pointer;
    width: 100px;
    margin: 0 20px;
  }
  .header nav .logo a {
    margin: 0;
  }
  .header nav .logo {
    width: 100px;
  }
  .header nav li a,
  .header nav li p {
    display: block;
    height: 30px;
    margin: 22px 0;
    padding: 8px 25px 6px;
    font-family: GoboldLowplus, sans-serif;
    font-size: 15px;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    color: #8f8f8f;
    background: #fec908;
    -webkit-transition: color 100ms ease-in-out;
    -moz-transition: color 100ms ease-in-out;
    -ms-transition: color 100ms ease-in-out;
    -o-transition: color 100ms ease-in-out;
    transition: color 100ms ease-in-out;
  }
  .header nav li a.active,
  .header nav li p.active {
    color: #000000;
  }
  .header nav li:first-child a {
    border-left: 0;
  }
  .header nav li:last-child p {
    border-right: 0;
  }
  .header nav li.last > a {
    border: none;
  }
  .header > b {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    bottom: -15px;
    left: 248px;
    margin-left: -4px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #000000;
    -webkit-transition: bottom 200ms ease-in-out, left 200ms ease-in-out;
    -moz-transition: bottom 200ms ease-in-out, left 200ms ease-in-out;
    -ms-transition: bottom 200ms ease-in-out, left 200ms ease-in-out;
    -o-transition: bottom 200ms ease-in-out, left 200ms ease-in-out;
    transition: bottom 200ms ease-in-out, left 200ms ease-in-out;
  }
  .header nav li a {
    padding: 8px 25px 6px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 992px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    z-index: 5;
    -webkit-transition: height 150ms ease-in-out;
    -moz-transition: height 150ms ease-in-out;
    -ms-transition: height 150ms ease-in-out;
    -o-transition: height 150ms ease-in-out;
    transition: height 150ms ease-in-out;
  }
  .header.open-mobile {
    height: 100%;
    background: #fec908;
  }
  .header .container {
    position: relative;
    width: 100%;
    max-width: none;
    margin-top: 55px;
    padding: 0;
  }
  .header button {
    position: absolute;
    z-index: 5;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .header nav {
    position: relative;
    float: left;
    width: 100%;
  }
  .header.open-mobile nav {
    padding-top: 150px;
  }
  .header nav h1 a {
    display: block;
    width: 166px;
    height: 55px;
  }
  .header nav ul {
    display: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 150ms ease-in-out;
    -moz-transition: max-height 150ms ease-in-out;
    -ms-transition: max-height 150ms ease-in-out;
    -o-transition: max-height 150ms ease-in-out;
    transition: max-height 150ms ease-in-out;
  }
  .header.open-mobile nav ul {
    display: block;
  }
  .header nav li {
    position: relative;
    float: left;
    display: block;
    width: 100%;
    text-align: center;
  }
  .header nav li p,
  .header nav li a {
    display: block;
    padding: 10px 10px 13px;
    color: #8f8f8f;
    font-family: GoboldLowplus, sans-serif;
    font-size: 25px;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: background 100ms ease-in-out;
    -moz-transition: background 100ms ease-in-out;
    -ms-transition: background 100ms ease-in-out;
    -o-transition: background 100ms ease-in-out;
    transition: background 100ms ease-in-out;
  }
  .header nav li p.active,
  .header nav li a.active {
    color: #000000;
  }
  .header.open-mobile h1 {
    left: auto;
  }
  .header.open-mobile ul {
    max-height: 367px;
  }
  .header .container {
    margin-top: 0px;
  }
  .header nav li.other_page_nav a {
    margin-top: 25px;
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .container {
    max-width: 80%;
  }
}
