.main-preloader {
  width: 100%;
  height: 100%;
  background-color: #fec908;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.preloader_logos_container {
  width: 100px;
  height: 100px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation: going_up 1s 2.5s forwards
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-animation: going_up 1s 2.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-animation: going_up 1s 2.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-animation: going_up 1s 2.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: going_up 1s 2.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.preloader_logo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.preloader_logo_pound {
  opacity: 0;
  transform-origin: center;
  -webkit-animation: scale_pound 1.5s 0.3s forwards
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-animation: scale_pound 1.5s 0.3s forwards
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-animation: scale_pound 1.5s 0.3s forwards
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-animation: scale_pound 1.5s 0.3s forwards
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: scale_pound 1.5s 0.3s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.preloader_logo_nelson {
  opacity: 0;
  -webkit-animation: logo_nelson 0.5s 1.8s forwards
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-animation: logo_nelson 0.5s 1.8s forwards
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-animation: logo_nelson 0.5s 1.8s forwards
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-animation: logo_nelson 0.5s 1.8s forwards
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: logo_nelson 0.5s 1.8s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@-webkit-keyframes scale_pound {
  0% {
    opacity: 1;
    transform: scale(3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-moz-keyframes scale_pound {
  0% {
    opacity: 1;
    transform: scale(3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-ms-keyframes scale_pound {
  0% {
    opacity: 1;
    transform: scale(3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scale_pound {
  0% {
    opacity: 1;
    transform: scale(3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes logo_nelson {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes logo_nelson {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes logo_nelson {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logo_nelson {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes logo_nelson {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes logo_opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes logo_opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logo_opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes going_up {
  0% {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 0%;
    transform: translate(-50%, 0%);
  }
}
@-moz-keyframes going_up {
  0% {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 0%;
    transform: translate(-50%, 0%);
  }
}
@-ms-keyframes going_up {
  0% {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 0%;
    transform: translate(-50%, 0%);
  }
}
@keyframes going_up {
  0% {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 0%;
    transform: translate(-50%, 0%);
  }
}
